import { lightBlue, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const systemazStyle = {
	light: 'rgb(120, 174, 224)',
	main: '#0062cc',
	// main: 'rgb(48, 120, 205)',
	dark: 'rgb(29, 78, 135)',
};

// const systemazStyle = {
// 	light: 'rgb(70, 150, 236)',
// 	main: 'rgb(53, 116, 224)',
// 	dark: 'rgb(48, 106, 183)',
// };

const azulCielo = {
	light: lightBlue[100],
	main: lightBlue[300],
	dark: lightBlue[500],
}

const lightText = {
	primary: 'rgb(17, 24, 39)',
	secondary: 'rgb(107, 114, 128)',
	disabled: 'rgb(149, 156, 169)',
};

// const lightText = {
// 	primary: 'rgba(0, 0, 0, 0.87)',
// 	secondary: 'rgba(0, 0, 0, 0.6)',
// 	disabled: 'rgba(0, 0, 0, 0.38)',
// };

const darkText = {
	primary: 'rgb(255,255,255)',
	secondary: 'rgb(229, 231, 235)',
	disabled: 'rgb(156, 163, 175)',
};


export const themesConfig = {
	default: {
		name: 'default',
		palette: {
			mode: 'light',
			text: lightText,
			common: {
				black: 'rgb(17, 24, 39)',
				white: 'rgb(255, 255, 255)',
			},
			primary: systemazStyle,
			secondary: azulCielo,
			background: {
				paper: '#FFFFFF',
				default: '#f6f7f9',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	defaultDark: {
		name: 'defaultDark',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: systemazStyle,
			secondary: azulCielo,
			background: {
				paper: '#1E2125',
				default: '#121212',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	legacy: {
		name: 'legacy',
		palette: {
			mode: 'light',
			text: lightText,
			primary: systemazStyle,
			secondary: azulCielo,
			// {
				// 	light: lightBlue[100],
				// 	main: lightBlue[400],
			// 	dark: lightBlue[600],
			// },
			background: {
				paper: '#FFFFFF',
				default: '#F7F7F7',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light1: {
		name: 'light1',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#b3d1d1',
				main: '#006565',
				dark: '#003737',
			},
			secondary: {
				light: '#ffecc0',
				main: '#FFBE2C',
				dark: '#ff9910',
				contrastText: '#272727',
			},
			background: {
				paper: '#FFFFFF',
				default: '#F0F7F7',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light2: {
		name: 'light2',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#fdf3da',
				main: '#f8d683',
				dark: '#f3bc53',
				contrastText: '#252525',
			},
			secondary: {
				light: '#FADCB3',
				main: '#F3B25F',
				dark: '#ec9339',
				contrastText: '#252525',
			},
			background: {
				paper: '#FAFBFD',
				default: '#FFFFFF',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light3: {
		name: 'light3',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#D9C8CE',
				main: '#80485B',
				dark: '#50212F',
			},
			secondary: {
				light: '#FFE3BF',
				main: '#FFB049',
				dark: '#FF8619',
				contrastText: '#252525',
			},
			background: {
				paper: '#FFF0DF',
				default: '#FAFAFE',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light4: {
		name: 'light4',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#CDCCE8',
				main: '#5854B1',
				dark: '#2D2988',
			},
			secondary: {
				light: '#F8EBF2',
				main: '#E7BDD3',
				dark: '#D798B7',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FFFFFF',
				default: '#F6F7FB',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light5: {
		name: 'light5',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#C2C7F1',
				main: '#3543D0',
				dark: '#161EB3',
			},
			secondary: {
				light: '#B3F1FE',
				main: '#00CFFD',
				dark: '#00B2FC',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FFFFFF',
				default: '#F7FAFF',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light6: {
		name: 'light6',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#BBE2DA',
				main: '#1B9E85',
				dark: '#087055',
			},
			secondary: {
				light: '#FFD0C1',
				main: '#FF6231',
				dark: '#FF3413',
				contrastText: '#FFF',
			},
			background: {
				paper: '#FFFFFF',
				default: '#F2F8F1',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light7: {
		name: 'light7',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#BFC4E6',
				main: '#2A3BAB',
				dark: '#0F1980',
			},
			secondary: {
				light: '#C2ECF0',
				main: '#33C1CD',
				dark: '#149EAE',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FFFFFF',
				default: '#EDF0F6',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light8: {
		name: 'light8',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#D2EFF2',
				main: '#68C8D5',
				dark: '#3AA7BA',
			},
			secondary: {
				light: '#FFF2C6',
				main: '#FED441',
				dark: '#FDB91C',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FAF6F3',
				default: '#FFFFFF',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light9: {
		name: 'light9',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#D3C0CD',
				main: '#6B2C57',
				dark: '#3C102C',
			},
			secondary: {
				light: '#FDEAC9',
				main: '#F9B84B',
				dark: '#F59123',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FFFFFF',
				default: '#FAFAFE',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light10: {
		name: 'light10',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#C6C9CD',
				main: '#404B57',
				dark: '#1C232C',
			},
			secondary: {
				light: '#FEEDC7',
				main: '#FCC344',
				dark: '#FAA11F',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#FFFFFF',
				default: '#F5F4F6',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	light11: {
		name: 'light11',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#C4C4C4',
				main: '#3A3A3A',
				dark: '#181818',
			},
			secondary: {
				light: '#EFEFED',
				main: '#CBCAC3',
				dark: '#ACABA1',
				contrastText: '#1E1F23',
			},
			background: {
				paper: '#EFEEE7',
				default: '#FAF8F2',
			},
			error: {
				light: '#F7EAEA',
				main: '#EBCECE',
				dark: '#E3B9B9',
			},
		},
		status: {
			danger: 'yellow',
		},
	},
	light12: {
		name: 'light12',
		palette: {
			mode: 'light',
			text: lightText,
			primary: {
				light: '#FFFAF6',
				main: '#FFEDE2',
				dark: '#FFE0CF',
			},
			secondary: {
				light: '#DBD8F7',
				main: '#887CE3',
				dark: '#584CD0',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#FFFFFF',
				default: '#FCF8F5',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark1: {
		name: 'dark1',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#C2C2C3',
				main: '#323338',
				dark: '#131417',
			},
			secondary: {
				light: '#B8E1D9',
				main: '#129B7F',
				dark: '#056D4F',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#262526',
				default: '#1E1D1E',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark2: {
		name: 'dark2',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#C9CACE',
				main: '#4B4F5A',
				dark: '#23262E',
			},
			secondary: {
				light: '#F8F5F2',
				main: '#E6DED5',
				dark: '#D5C8BA',
				contrastText: '#23262E',
			},
			background: {
				paper: '#31343E',
				default: '#2A2D35',
			},
			error: {
				light: '#F7EAEA',
				main: '#EBCECE',
				dark: '#E3B9B9',
			},
		},
		status: {
			danger: 'orange',
		},
	},
	dark3: {
		name: 'dark3',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#C2C8D2',
				main: '#354968',
				dark: '#16213A',
			},
			secondary: {
				light: '#F4CFCA',
				main: '#D55847',
				dark: '#C03325',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#23354E',
				default: '#1B2A3F',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark4: {
		name: 'dark4',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#CECADF',
				main: '#5A4E93',
				dark: '#2E2564',
			},
			secondary: {
				light: '#B3EBD6',
				main: '#00BC77',
				dark: '#009747',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#22184B',
				default: '#180F3D',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark5: {
		name: 'dark5',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#CCD7E2',
				main: '#56789D',
				dark: '#2B486F',
			},
			secondary: {
				light: '#D7D3ED',
				main: '#796CC4',
				dark: '#493DA2',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#465261',
				default: '#232931',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark6: {
		name: 'dark6',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#FFC7CE',
				main: '#FF445D',
				dark: '#FF1F30',
			},
			secondary: {
				light: '#B4E3FB',
				main: '#05A2F3',
				dark: '#0175EA',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#2F3438',
				default: '#25292E',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark7: {
		name: 'dark7',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: 'FFECC5',
				main: '#FEBE3E',
				dark: '#FD991B',
			},
			secondary: {
				light: '#FFC8C7',
				main: '#FE4644',
				dark: '#FD201F',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#2A2E32',
				default: '#212529',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark8: {
		name: 'dark8',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#BEBFC8',
				main: '#252949',
				dark: '#0D0F21',
			},
			secondary: {
				light: '#CBD7FE',
				main: '#5079FC',
				dark: '#2749FA',
				contrastText: '#1A1E22',
			},
			background: {
				paper: '#2D3159',
				default: '#202441',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark9: {
		name: 'dark9',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#BCC8CD',
				main: '#204657',
				dark: '#0B202C',
			},
			secondary: {
				light: '#B3EBC5',
				main: '#00BD3E',
				dark: '#00981B',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#1C1E27',
				default: '#15171E',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark10: {
		name: 'dark10',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#C3C2D2',
				main: '#36336A',
				dark: '#16143C',
			},
			secondary: {
				light: '#D6CEFC',
				main: '#765CF5',
				dark: '#4630EE',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#2D2A5D',
				default: '#26244E',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark11: {
		name: 'dark11',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#BFB7BF',
				main: '#2A0F29',
				dark: '#0F040F',
			},
			secondary: {
				light: '#D9B9C3',
				main: '#801737',
				dark: '#500716',
				contrastText: '#FFFFFF',
			},
			background: {
				paper: '#200D1F',
				default: '#2D132C',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	dark12: {
		name: 'dark12',
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: '#CCC3C8',
				main: '#543847',
				dark: '#291720',
			},
			secondary: {
				light: '#DFB8BD',
				main: '#BE717A',
				dark: '#99424A',
				contrastText: '#1a161c',
			},
			background: {
				paper: '#4D4351',
				default: '#27141F',
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
	greyDark: {
		palette: {
			mode: 'dark',
			text: darkText,
			primary: {
				light: systemazStyle[200],
				main: systemazStyle[700],
				dark: systemazStyle[800],
			},
			secondary: azulCielo,
			background: {
				paper: systemazStyle[400],
				default: systemazStyle[500],
			},
			error: red,
		},
		status: {
			danger: 'orange',
		},
	},
};


export const SelectTema = (nTema) => {
	switch (nTema) {
		case 'default':
			return createTheme({
				...themesConfig.default
			});
		case 'defaultDark':
			return createTheme({
				...themesConfig.defaultDark
			});
		case 'legacy':
			return createTheme({
				...themesConfig.legacy
			});
		case 'light1':
			return createTheme({
				...themesConfig.light1
			});
		case 'light2':
			return createTheme({
				...themesConfig.light2
			});
		case 'light3':
			return createTheme({
				...themesConfig.light3
			});
		case 'light4':
			return createTheme({
				...themesConfig.light4
			});
		case 'light5':
			return createTheme({
				...themesConfig.light5
			});
		case 'light6':
			return createTheme({
				...themesConfig.light6
			});
		case 'light7':
			return createTheme({
				...themesConfig.light7
			});
		case 'light8':
			return createTheme({
				...themesConfig.light8
			});
		case 'light9':
			return createTheme({
				...themesConfig.light9
			});
		case 'light10':
			return createTheme({
				...themesConfig.light10
			});
		case 'light11':
			return createTheme({
				...themesConfig.light11
			});
		case 'light12':
			return createTheme({
				...themesConfig.light12
			});
		case 'dark1':
			return createTheme({
				...themesConfig.dark1
			});
		case 'dark2':
			return createTheme({
				...themesConfig.dark2
			});
		case 'dark3':
			return createTheme({
				...themesConfig.dark3
			});
		case 'dark4':
			return createTheme({
				...themesConfig.dark4
			});
		case 'dark5':
			return createTheme({
				...themesConfig.dark5
			});
		case 'dark6':
			return createTheme({
				...themesConfig.dark6
			});
		case 'dark7':
			return createTheme({
				...themesConfig.dark7
			});
		case 'dark8':
			return createTheme({
				...themesConfig.dark8
			});
		case 'dark9':
			return createTheme({
				...themesConfig.dark9
			});
		case 'dark10':
			return createTheme({
				...themesConfig.dark10
			});
		case 'dark11':
			return createTheme({
				...themesConfig.dark11
			});
		case 'dark12':
			return createTheme({
				...themesConfig.dark12
			});

		default:
			return //mdTheme;

	}
}


export const opcionesTema = [
	{ id: 1, label: 'default', primary: themesConfig.default.palette.primary.main, secondary: themesConfig.default.palette.secondary.main },
	{ id: 2, label: 'defaultDark', primary: themesConfig.defaultDark.palette.primary.main, secondary: themesConfig.defaultDark.palette.secondary.main },
	{ id: 3, label: 'legacy', primary: themesConfig.legacy.palette.primary.main, secondary: themesConfig.legacy.palette.secondary.main },
	{ id: 4, label: 'light1', primary: themesConfig.light1.palette.primary.main, secondary: themesConfig.light1.palette.secondary.main },
	{ id: 5, label: 'light2', primary: themesConfig.light2.palette.primary.main, secondary: themesConfig.light2.palette.secondary.main },
	{ id: 6, label: 'light3', primary: themesConfig.light3.palette.primary.main, secondary: themesConfig.light3.palette.secondary.main },
	{ id: 7, label: 'light4', primary: themesConfig.light4.palette.primary.main, secondary: themesConfig.light4.palette.secondary.main },
	{ id: 8, label: 'light5', primary: themesConfig.light5.palette.primary.main, secondary: themesConfig.light5.palette.secondary.main },
	{ id: 9, label: 'light6', primary: themesConfig.light6.palette.primary.main, secondary: themesConfig.light6.palette.secondary.main },
	{ id: 10, label: 'light7', primary: themesConfig.light7.palette.primary.main, secondary: themesConfig.light7.palette.secondary.main },
	{ id: 11, label: 'light8', primary: themesConfig.light8.palette.primary.main, secondary: themesConfig.light8.palette.secondary.main },
	{ id: 12, label: 'light9', primary: themesConfig.light9.palette.primary.main, secondary: themesConfig.light9.palette.secondary.main },
	{ id: 13, label: 'light10', primary: themesConfig.light10.palette.primary.main, secondary: themesConfig.light10.palette.secondary.main },
	{ id: 14, label: 'light11', primary: themesConfig.light11.palette.primary.main, secondary: themesConfig.light11.palette.secondary.main },
	{ id: 15, label: 'light12', primary: themesConfig.light12.palette.primary.main, secondary: themesConfig.light12.palette.secondary.main },
	{ id: 16, label: 'dark1', primary: themesConfig.dark1.palette.primary.main, secondary: themesConfig.dark1.palette.secondary.main },
	{ id: 17, label: 'dark2', primary: themesConfig.dark2.palette.primary.main, secondary: themesConfig.dark2.palette.secondary.main },
	{ id: 18, label: 'dark3', primary: themesConfig.dark3.palette.primary.main, secondary: themesConfig.dark3.palette.secondary.main },
	{ id: 19, label: 'dark4', primary: themesConfig.dark4.palette.primary.main, secondary: themesConfig.dark4.palette.secondary.main },
	{ id: 20, label: 'dark5', primary: themesConfig.dark5.palette.primary.main, secondary: themesConfig.dark5.palette.secondary.main },
	{ id: 21, label: 'dark6', primary: themesConfig.dark6.palette.primary.main, secondary: themesConfig.dark6.palette.secondary.main },
	{ id: 22, label: 'dark7', primary: themesConfig.dark7.palette.primary.main, secondary: themesConfig.dark7.palette.secondary.main },
	{ id: 23, label: 'dark8', primary: themesConfig.dark8.palette.primary.main, secondary: themesConfig.dark8.palette.secondary.main },
	{ id: 24, label: 'dark9', primary: themesConfig.dark9.palette.primary.main, secondary: themesConfig.dark9.palette.secondary.main },
	{ id: 25, label: 'dark10', primary: themesConfig.dark10.palette.primary.main, secondary: themesConfig.dark10.palette.secondary.main },
	{ id: 26, label: 'dark11', primary: themesConfig.dark11.palette.primary.main, secondary: themesConfig.dark11.palette.secondary.main },
	{ id: 27, label: 'dark12', primary: themesConfig.dark12.palette.primary.main, secondary: themesConfig.dark12.palette.secondary.main },
];

