import { Divider, Box, Typography } from '@mui/material';
import React from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import { useSelector } from 'react-redux';

import { globalStyles } from '../Styles/Styles'

export const PanelEmpresa = () => {
    const classes = globalStyles();
    // const { company } = useSelector(state => state.cia);

    return (
        <>
            <Box className={ classes.panelCIA }>
                <AccountBalanceIcon 
                    sx={{ 
                        height: (theme) => theme.spacing(12),  
                        width: (theme) => theme.spacing(12),  
                        m: 3,
                        mb: 5
                    }}

                />
                <Typography variant='subtitle1' color='inherit' align='center' mt={-3} mb={2} >
                    {/* { company } */}
                </Typography>           
            </Box>
            <Divider />
        </>
    )
}
