import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { textAlign } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(120),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },

}));

const ColoredLinearProgress = () => {
    const classes = useStyles();
    return (
        <LinearProgress
            // {...props}
            classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary
            }}
        />
    );
}

export const Waiting = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ColoredLinearProgress />
      {/* <LinearProgress color='primary'/> */}
    </div>
  );
}

export const WaitCircular = () => {
  return (
    <CircularProgress color="secondary" size={25} 
    // thickness={6.0}
    /> 
  )
}
