import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { 
  Box, 
  // FormHelperText, 
  Typography 
} from '@mui/material';

import { TextEdit } from '../FileShared/TextEdit';
import { getBancos } from '../actions/acBancos';
import { WaitCircular } from '../FileShared/Wating/Waiting';


const filterOptions = createFilterOptions({
  stringify: ({ cta, titular, banco }) => `${cta} ${titular} ${banco}`
});

// const sleep = (delay = 0) => {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

export const LookupBanco = ( props ) => {

  const { cta, setValues, required } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState(cta);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(3000);
      const response = await getBancos();
      const data = [...response]
      console.log( data )

      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChangeValue = (newValue) => {
    setValue(newValue);
    setValues({ ...newValue })
  }

  const onChangeInput = (newInputValue) => {
    setInputValue(newInputValue);
  }

  return (
    <>
      <Autocomplete
        id="edCta"
        open={open}
        onOpen={() => { setOpen(true) }}
        onClose={() => { setOpen(false) }}
        size='small'
        isOptionEqualToValue={(option, value) => option.idbanco === value.idbanco}
        getOptionLabel={(option) => option.titular}
        options={options}
        loading={loading}

        filterOptions={ filterOptions }
        filterSelectedOptions
        renderOption={(props, option) => {
          return (
            <Box  {...props}>
              <Typography variant='subtitle2'> 
                {option.cta}  {option.banco}  {option.titular}
              </Typography>
              {/* <Typography variant='subtitle1'>{option.titular}</Typography> */}
            </Box>
          );
        }}

        fullWidth
        value={value}
        onChange={(event, newValue) => { onChangeValue(newValue); }}

        inputValue={inputValue}
        onInputChange={(event, newInputValue) => { onChangeInput(newInputValue); }}

        renderInput={(params) => (
          <TextEdit
            {...params}
            {...props}
            required={required}
            error={required && (cta === undefined || cta === '')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <WaitCircular /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {/* <FormHelperText error >
        {(required && (cta === undefined || cta === '')) ? 'El campo cuenta es obligatorio' : <span></span>}
      </FormHelperText> */}

    </>
  )
}