import { Box } from '@mui/system';
import { Header } from '../components/Header';
import { MainMenu } from '../components/MainMenu';
import { SelectThem } from '../components/SelectThem';

export const HomePage = () => {
    return (
        <>
          {/* <Box
              sx={{
                // display: 'flex',
                width: '100%',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                color: 'text.primary',
                // overflow: 'hidden',
                // borderRadius: 1,
                p: 3,
              }}
          >
          </Box> */}

          <Header />
          <MainMenu /> 
          <SelectThem />

        </>
    )
}
