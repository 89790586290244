import React, { useEffect, useState } from 'react';
import { Box, Fab } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { format } from 'date-fns';

import { Acordion, AcordionSummary, AcordionDetalle } from '../FileShared/Acordion';
import { Waiting } from '../FileShared/Wating/Waiting';
import { TextDate } from '../FileShared/TextDate';
import { setError, setLoading } from '../actions/acUI';
import { getRepSaldos } from '../actions/acBancos';
import { countryToFlag, setPesos } from '../FileShared/Util/Util';

export const RepSaldos = () => {

    const { loading } = useSelector(state => state.ui);
    const [ejecuta, setEjecuta] = useState( false );
    const [data, setData] = useState([]);
    const [params, setParams] = useState({ w1: '', });
    const [wDate, setwDate] = useState( new Date() )

    const [verParams, setVerParams] = useState(true);
    const dispatch = useDispatch();

    const txtEtiquetas = {
        body: {
            noMatch: "UPS, No se encontraron registros...",
            toolTip: "Ordenar",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
            next: "Sig Pagina",
            previous: "Pagina anterior",
            rowsPerPage: "Registros por pagina:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "Filtros",
        },
        filter: {
            all: "Todo",
            title: "FILTROS",
            reset: "LIMPIAR",
        },
        viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
            text: "row(s) selected",
            delete: "Borrar",
            deleteAria: "Delete Selected Rows",
        },
    }

    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
        fixedHeader: true,
        // pagination: false,
        tableBodyHeight: 'calc(100vh - 230px)',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100, 500],
        searchPlaceholder: 'Buscar...',
        selectableRows: 'none',
        draggableColumns: { enabled: true, },
        textLabels: txtEtiquetas,
    };


    const columnas = [
        {name: 'cta', label:'Cuenta', options:{filter: false }},
        {name: 'titular', label:'Nombre', options:{filter: false, setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'banco', label:'Banco', options:{setCellProps: () => ( {style: {whiteSpace:'nowrap'}})}},
        {name: 'moneda', label:'Moneda', options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <Typography variant='h5'>
                  { value !== 0 ? countryToFlag ('US') : countryToFlag ('MX')}
                  </Typography>
              );
            }
        }},

        {name: 'saldo', label: 'Saldo', options:{ filter: false, 
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
        {name: 'saldoc', label: 'Saldo Conciliado', options:{ filter: false, 
            setCellProps: () => ({ align: 'right' }),
            customBodyRender: (value, tableMeta, updateValue) => setPesos( value ),
            setCellHeaderProps: () => ({ align: 'right'}),
        }},
    ]


    const acExecute =  () => {
        setEjecuta( false );
        if (wDate === null ) {
            dispatch( setError('La fecha es obligatoria'));
            return;
        }

        setParams({
            w1: format(wDate , 'yyyyMMdd'),
        })
        setEjecuta( true );
        setVerParams(false)
    }

    useEffect(() => {
        if ( ejecuta ){ 
            dispatch( setLoading(true) );
            const fetchData = async() => {
               const result = await getRepSaldos( params.w1 );
               setData([ ...result ]);           
               dispatch( setLoading(false) );
            };
            fetchData(); 
        }
            return () => { setData([]); }
    }, [dispatch, ejecuta, params.w1]);


    return (
        <Box sx={{ flex: 1, height: '100vh' }}>

            <Acordion
                expanded={verParams}
                onChange={() => setVerParams(!verParams)}
            >
                <AcordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >Parametros</Typography>
                </AcordionSummary>
                <AcordionDetalle>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Fab
                            size='large'
                            onClick={acExecute}
                            color='primary'
                            sx={{ position: 'fixed' }}
                        >
                            <SearchIcon />
                        </Fab>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={5} md={3} >
                            <TextDate
                                required
                                label='Fecha'
                                value={ wDate }
                                autoFocus
                                onChange={date => setwDate( date )}
                            />
                        </Grid>

                    </Grid>
                </AcordionDetalle>
            </Acordion>

            {loading && <Waiting />}

            <MUIDataTable
                title='Reporte de Saldos'
                columns={columnas}
                options={options}
                data={data}
            />
        </Box>
    )
}
