import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { RutasApp } from './RutasApp';
import { LoginPage } from '../pages/LoginPage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSkin, setWorking } from '../actions/acUI';
import { getUser } from '../actions/acAuth';
import { Splash } from '../components/Splash';

export const AppRouter = () => {

    const { working }  = useSelector( state => state.ui )

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( setWorking( true ));

        // Lee skin grabado 
        const askin = localStorage.getItem('skin');
        askin !== '' && dispatch( setSkin( askin ));

        setTimeout(() => {
            let ul = null;

            if ( localStorage.getItem('lastuser') !== null &&  localStorage.getItem('lastuser') !== '') {
              ul = JSON.parse( localStorage.getItem('lastuser') )
            }

            if (ul !== null && ul !== '' ) {
                dispatch( getUser( ul ));
            }       

            dispatch( setWorking( false ));

        }, 1500);

    }, [ dispatch ])

    if ( working ) return ( <Splash /> )

    return (
        <BrowserRouter>
            <CssBaseline />
            <Routes>
                <Route path='/login' element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                }
                />

                <Route path='/*' element={
                    <PrivateRoute>
                        <RutasApp />
                    </PrivateRoute>
                }
                />

            </Routes>
        </BrowserRouter>
    )
}
