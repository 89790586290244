import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';

import { PanelEmpresa } from './PanelEmpresa';
import { mainMenuClose } from '../actions/acUI';
import { MenuOpciones } from '../data/menus'
import { globalStyles } from '../Styles/Styles';

const menu0 = () => MenuOpciones.filter(item => item.parentid === 0);

const MenuContent = ({ opciones, acRegresar, onOpenItems, acEjecuta, acHome }) => {
    const classes = globalStyles();
    
    let lastOpcion;
    const nodo = opciones[0];
    if (nodo && nodo.parentid > 0) {
        const padre = MenuOpciones.find(item => item.id === nodo.parentid);
        lastOpcion = (
            <Box 
                sx={{ 
                    p: 0.2, 
                    textTransform: 'uppercase', 
                    fontWeight: '500', 
                    display: 'flex', 
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                }}
                boxShadow={1} 
                >
                <Tooltip title="Regresar al menu anterior">
                    <IconButton onClick={() => acRegresar(padre)} >
                        <ArrowBackIcon className={classes.btnBack} />
                    </IconButton>
                </Tooltip>
                
                <Divider orientation="vertical" flexItem />

                <Box sx={{ml: 2}} component='span' >
                    {padre.label}
                </Box>
            </Box>
        )
    }
    
    return (
        <Box className={classes.drawerPaper} >
            <Link to='/' className={classes.link} >
                <ListItem button onClick={acHome}  >
                    <ListItemIcon sx={{ color: 'primary.main' }} > 
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Inicio' />
                </ListItem>
            </Link>
            <Divider />

            {lastOpcion}
            <List>
                {opciones.map(opcion => {
                    let subOpciones;

                    if (opcion.link !== '') {
                        subOpciones = (
                            <Link key={opcion.id} to={opcion.link} className={classes.link} >
                                <ListItem key={opcion.id} button onClick={() => acEjecuta(opcion)} >
                                    <ListItemIcon sx={{ color: 'primary.main' }}>
                                        {opcion.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={opcion.label} />
                                </ListItem>
                            </Link>
                        )
                    } else if (opcion.link === '') {
                        subOpciones = (
                            <ListItem key={opcion.id} button onClick={() => onOpenItems(opcion)} >
                                <ListItemIcon sx={{ color: 'primary.main' }}>
                                    {opcion.icon}
                                </ListItemIcon>
                                <ListItemText primary={opcion.label} />
                                <ArrowRightIcon color="action" fontSize="small" />
                            </ListItem>
                        )
                    }
                    return subOpciones;
                })}
            </List>
        </Box>
    )
}

export const MainMenu = () => {
    const { openMenu } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    const handleCloseMenu = () => {
        dispatch(mainMenuClose());
    }
    
    const [currentMenu, setCurrentMenu] = React.useState(menu0());

    const acRegresar = (padre) => {
        const subMenu = MenuOpciones.filter(item => item.parentid === padre.parentid);
        setCurrentMenu(subMenu);
    }

    const onOpenItems = (opcion) => {
        const subMenu = MenuOpciones.filter(item => item.parentid === opcion.id);
        setCurrentMenu(subMenu);
    }

    const acHome = () => {
        handleCloseMenu();
        setCurrentMenu(menu0);
    }

    return (
        <>
            <Drawer
                open={openMenu}
                onClose={handleCloseMenu}
            >
                <PanelEmpresa />
                <MenuContent
                    opciones={currentMenu}
                    acRegresar={acRegresar}
                    onOpenItems={onOpenItems}
                    acEjecuta={handleCloseMenu}
                    acHome={acHome}
                />
            </Drawer>
        </>
    );
}
