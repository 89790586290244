import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaletteIcon from '@mui/icons-material/Palette';
import { logout } from '../actions/acAuth';
import { globalStyles } from '../Styles/Styles';
import { menuUser } from '../data/menus';
import { mainMenuOpen, setMenuSkin, setTema } from '../actions/acUI';


export const Header = () => {
  const classes = globalStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { imagen, iniciales, mail, nombre } = useSelector(state => state.usuario);
  // const { darkState } = useSelector(state => state.ui);
  const { company } = useSelector(state => state.cia);
  const navigate = useNavigate();


  const acHome = () => {
    navigate('/', {
      replace: true
    });
  }

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const onClickMenu = () => {
    dispatch(mainMenuOpen());
  }

  const onClickSkin = () => {
    dispatch( setMenuSkin( true ) );
    setAnchorEl(null);
  }

  const handleLogOut = () => {
    setAnchorEl(null);
    dispatch( logout() );
}

  const MenuUser = (
    <Box>
      <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
      >
            <Box className={ classes.panel }>
                <Avatar 
                  sx={{ 
                    height: (theme) => theme.spacing(10),  
                    width: (theme) => theme.spacing(10),  
                    mb: 2
                  }}
                  alt='Imagen User'  
                >
                    { iniciales }
                </Avatar>
                <Typography variant='subtitle2' color="initial" align='center' >
                    { nombre }
                </Typography> 
                <Typography variant='caption' color="initial" align='center' >
                    { mail }
                </Typography> 
            </Box>

            <Divider />


            {/* <MenuItem onClick={ handleThemeChange }>
              <IconButton aria-label="" color="inherit">
                  { darkState ? <BrightnessHighIcon /> : <Brightness4Icon  />}
              </IconButton>
                Modo obscuro
            </MenuItem> */}

            <MenuItem onClick={ onClickSkin }>
              <IconButton aria-label="" color="inherit">
                <PaletteIcon /> 
              </IconButton>
                Theme
            </MenuItem>



            { menuUser.map( item => {
                return (
                  <MenuItem key={ item.label }>
                    <IconButton key={ item.key } color="inherit" >  { item.icon } </IconButton>
                    { item.label }
                  </MenuItem>
                )
              })}

            <MenuItem onClick={ handleLogOut }>
              <IconButton aria-label="" color="inherit">
                  <ExitToAppIcon /> 
              </IconButton>
                Cerrar sesion
            </MenuItem>

      </Menu>
    </Box>
  ) 
  

  return (
    <Box className={classes.grow} >
      <AppBar position="fixed" enableColorOnDark>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onClickMenu}

          >
            <MenuIcon />
          </IconButton>

          <Typography 
            variant="h6" 
            component="div" 
            sx={{ cursor: 'pointer' }} 
            onClick={ acHome } 
          >
            { company }
          </Typography>
           
          <Box sx={{ flexGrow: 1 }}>

          </Box>

          <div>
            <IconButton
              size="large"
              aria-label="user account"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={ handleMenu }
              color="inherit"
            >
              <Avatar sx={{ bgcolor: '_inherit' }} >
                <PersonIcon />
              </Avatar>
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      { MenuUser }
    </Box>
  )
}
