import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';


import { countryToFlag, showSuccess, siBorrar } from '../FileShared/Util/Util';
import { CustomCatalogos } from '../FileShared/CustomCatalogos/CustomCatalogos';
import { setError, setLoading } from '../actions/acUI';
import { DeleteBanco, getBancos } from '../actions/acBancos';
import { CapBan } from './CapBan';


export const BrowBan = () => {

    const inicial = {
        idbanco: -1,
        iduser: -1,
        idempresa: -1,
        cta: '',
        titular: '',
        banco: '',
        sucursal: '',
        direccion: '',
        telefono: '',
        saldo: 0,
        saldocon: 0,
        moneda: 0,
        ejecutivo: '',
        alta: '',
        activo: 1,
        tipo: 'A',
    }

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [record, setRecord] = useState(inicial);
    const [alta, setAlta] = useState(null);

    const columns = [
        // La primer columna debe de ser siempre el ID que identifique el registro 
        { name: 'idbanco', label: ' ', options: { filter: false, viewColumns: false, empty: true, print: false, sort: false, display: false} },
        { name: 'cta', label: 'Cuenta', options: { filter: false } },
        { name: 'titular', label: 'Nombre', options: { filter: false, setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }) } },
        { name: 'banco', label: 'Banco', options: { setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }) } },
        { name: 'sucursal', label: 'Sucursal', options: { setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }) } },
        {
            name: 'moneda', label: 'Moneda', options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography variant='h5'>
                            {value !== 0 ? countryToFlag('US') : countryToFlag('MX')}
                        </Typography>
                    );
                }
            }
        },
    ]

    const acOpciones = [
        { id: 10, caption: 'Editar', icon: <EditIcon />, accion: 'acEdit' },
        { id: 20, caption: 'Borrar', icon: <DeleteIcon />, accion: 'acDelete' },
    ]

    const acAgregar = () => {
        setAlta(true);
        setOpen(true);
    }

    const edita = ( aValue ) => {
        setRecord( aValue )
        const nuevo = [];
        data.forEach( item => (
            item.idbanco !== aValue.idbanco ? nuevo.push( {...item}) : nuevo.push({ ...aValue })
        ))
        setData([ ...nuevo ])   
    }
    
    const acClose = () => {
        setRecord( inicial );
        setOpen(false);
    }

    const onClickMenu = (opcion, record ) => {
        const idBan = record[1];
        const nuevo = data.filter( item => ( item.idbanco === idBan ));
        
        setRecord( { ...nuevo[0] });
        setAlta(false);

        if (opcion === 'acEdit') onClickEdit();
        if (opcion === 'acDelete') onClickDelete();
    }

    const onClickEdit = () => {
        setOpen(true);
    }
    
    const onClickDelete = async() => {
        const respuesta = await siBorrar();
        if ( respuesta === true ) {
            const response = await DeleteBanco( record.cta );
            if ( response.coderesult === 200) {
                const nuevo = [];
                data.forEach( item => (
                    item.idbanco !== record.idbanco && nuevo.push( {...item })
                ))
                showSuccess( response.Message );
                setData([ ...nuevo ])
            } else {
                setError( response.Message )
            }
        }
    }

    useEffect(() => {
        dispatch( setLoading(true) );
        const fetchData = async() => {
           const result = await getBancos();
           setData([ ...result ]);           
           dispatch( setLoading(false) );
        };
        fetchData();
        return () => { setData([]); }
    }, [ dispatch ]);

    return (
        <>
            {open && <CapBan open={open} onClose={acClose} registro={record} alta={alta} edita={edita}/>}
            <CustomCatalogos
                titulo='Cuentas Bancarias'
                campos={columns}
                data={data}
                onClickAdd={acAgregar}
                onClickMenu={onClickMenu}
                acOpciones={acOpciones}
            />
        </>
    )
}
