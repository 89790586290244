import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format } from 'date-fns';

import { LookupBanco } from '../components/LookupBanco';
import { setPesos, showMessage, showSuccess, siBorrar } from '../FileShared/Util/Util';
import { ShowError } from '../FileShared/ShowError/ShowError';
import { setError, setLoading } from '../actions/acUI';
import { deleteMovBan, getEdoCtaBan } from '../actions/acBancos';
import { CustomReporte } from '../FileShared/CustomReporte/CustomReporte';
import { EditMov } from '../components/EditMov';

const Paramateros = ({ cta, setValues }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10} md={6} lg={6}>
                    <LookupBanco required name='cta' label='Cuenta' cta={cta} setValues={setValues} />
                </Grid>
            </Grid>
        </>
    )
}

export const EdoCtaBan = () => {

    const dispatch = useDispatch();
    const [whatis, setWhatis] = useState('');
    const [record, setRecord] = useState([]);
    const [open, setOpen] = useState(false);
    const { msgError } = useSelector(state => state.ui);
    const [data, setData] = useState([]);
    const [ejecuta, setEjecuta] = useState(false);

    const menuOpciones = [
        { id: 10, caption: 'Editar', icon: <EditIcon />, accion: 'acEdit' },
        { id: 20, caption: 'Borrar', icon: <DeleteIcon />, accion: 'acDelete' },
    ]

    const onClickMenu = (opcion, record ) => {
        const idMov = record[1];
        const nuevo = data.filter( item => ( item.idmov === idMov ));

        setRecord( { ...nuevo[0],
            cta: cta,
            titular: titular,
        } );
        
        if (opcion === 'acEdit') onClickEdit();
        if (opcion === 'acDelete') onClickDelete( idMov );
    }
      
    const onClickEdit = () => {
        setWhatis('Editar');
        setOpen(true);
    }

    const onClickDelete = async( idMov ) => {
        const respuesta = await siBorrar();
        if ( respuesta === true ) {
            const response = await deleteMovBan( idMov );
            if ( response.coderesult === 200) {
                const nuevo = [];
                data.forEach( item => (
                    item.idmov !== idMov && nuevo.push( {...item })
                ))
                showSuccess( response.Message );
                setData([ ...nuevo ])
                showSuccess('Registro eliminado')
            } else {
                setError( response.Message )
            }
        }
    }


    const columns = [
        // La primer columna debe de ser siempre el ID que identifique el registro 
        { name: 'idmov', label: ' ', options: { filter: false, viewColumns: false, empty: true, print: false, sort: false, display: false} },
        {
            name: 'concilia', label: ' ', options: { sort: false, filter: false, viewColumns: false, 
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value === '*' ? <CheckBoxIcon fontSize="small" color='primary' />
                                : <CheckBoxOutlineBlankIcon fontSize="small" color='primary' />
                            }
                        </>
                    );
                }
            }
        },
        { name: 'afecha', label: 'Fecha', options: { filter: false, setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }) } },
        { name: 'concepto', label: 'Concepto', options: { filter: false } },
        { name: 'cheque', label: 'Refer. / Cheque', options: { setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }) } },
        {
            name: 'cargo', label: 'Retiros / Cargos', options: {
                setCellProps: () => ({ align: 'right' }),
                customBodyRender: (value, tableMeta, updateValue) => setPesos(value),
                setCellHeaderProps: () => ({ align: 'right' }),
            }
        },
        {
            name: 'abono', label: 'Depositos / Abonos', options: {
                setCellProps: () => ({ align: 'right' }),
                customBodyRender: (value, tableMeta, updateValue) => setPesos(value),
                setCellHeaderProps: () => ({ align: 'right' }),
            }
        },
        {
            name: 'saldo', label: 'Saldo', options: {
                setCellProps: () => ({ align: 'right' }),
                customBodyRender: (value, tableMeta, updateValue) => setPesos(value),
                setCellHeaderProps: () => ({ align: 'right' }),
            }
        },
        {
            name: 'saldoc', label: 'Saldo Conciliado', options: {
                setCellProps: () => ({ align: 'right' }),
                customBodyRender: (value, tableMeta, updateValue) => setPesos(value),
                setCellHeaderProps: () => ({ align: 'right' }),
            }
        },

    ]

    const edita = ( aValue ) => {
        const nuevo = [];
        let saldo = 0;

        data.forEach( item => {
            if ( item.idmov !== aValue.idmov ) {
                saldo = saldo +  ( item.abono - item.cargo);
                nuevo.push( { ...item,   saldo: saldo });
            } else {
                saldo = saldo +  ( aValue.abono - aValue.cargo);
                nuevo.push( { ...aValue,   saldo: saldo });
            }
        })

        setData([ ...nuevo ])   
    }

    const opciones = {
        sort: false,
        filter: false,
        draggableColumns: { enabled: false, },
    }

    const [params, setParams] = useState({
        w1: '',
        w2: '',
        cta: '',
    });

    const [values, setValues] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    })
    const { cta, titular } = values;

    const acClose = () => {
        // setRegistro( inicial );
        setOpen(false);
        setWhatis('');
    }

    const showError = (msg, tipo) => {
        ShowError('error', msg);
        dispatch(setError(null));
    }

    const acExecute = (fechas) => {
        setEjecuta(false);
        if (fechas.wDate1 === null) {
            dispatch(setError('La fecha inicial es obligatoria'));
            return;
        }
        if (fechas.wDate2 === null) {
            dispatch(setError('La fecha final es obligatoria'));
            return;
        }
        if (values?.cta.length === 0) {
            dispatch(setError('Falta la cuenta'));
            return;
        }

        setParams({
            w1: format(fechas.wDate1, 'yyyyMMdd'),
            w2: format(fechas.wDate2, 'yyyyMMdd'),
            cta: values.cta
        })
        setEjecuta(true);
    }

    useEffect(() => {
        if (ejecuta) {
            dispatch(setLoading(true));
            const fetchData = async () => {
                const result = await getEdoCtaBan(params.w1, params.w2, params.cta);
                dispatch(setLoading(false));
                result ||  showMessage('no data')
                setData([...result]);
            };
            fetchData();
        }
        // dispatch(setLoading(false));
        return () => { setData([]); }
    }, [dispatch, ejecuta, params.cta, params.w1, params.w2]);

    return (
        <Box >
            {msgError && showError(msgError, 'error')}
            { whatis === 'Editar' && <EditMov open={open} onClose={acClose} registro={ record } edita={edita}/>}
            <CustomReporte
                titulo='Estado de Cuenta'
                campos={ columns }
                data={ data }
                acExecute={ acExecute }
                onClickMenu={ onClickMenu }
                extra={<Paramateros cta={cta} titular={titular} setValues={setValues} />}
                menuOpciones={ menuOpciones }
                opciones={ opciones }
            />
        </Box>
    )
}
