import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'

import { useForm } from '../hooks/useForm';
import { TextEdit } from '../FileShared/TextEdit';
import { TextDate } from '../FileShared/TextDate';
import { countryToFlag, monedas, showSuccess } from '../FileShared/Util/Util';
import { BotonCancel, BotonOK } from '../Styles/Styles';
import { setError } from '../actions/acUI';
import { ShowError } from '../FileShared/ShowError/ShowError';
import { saveBanco, updateBanco } from '../actions/acBancos';


const PaperComponent = (props) => {
    return (
        <Draggable handle="#CapBan" cancel={'[class*="MuiDialogContent-root"]'} >
            <Paper {...props} />
        </Draggable>
    );
}

export const CapBan = ({ open, onClose, registro, alta, edita }) => {

    const dispatch = useDispatch();
    const [ values, onInputChange ] = useForm(registro);
    const { msgError } = useSelector(state => state.ui);
    const [ wDate, setwDate ] = useState(new Date());
    const [ fieldsErr, setFieldsErr ] = React.useState({
        ValidaDatos: false,
        ctaErr: false,
        fechaErr: false,
    });
    
    let titulo = alta ? 'Alta de Cuentas Bancarias' : 'Cambio de Cuentas Bancarias';

    const {
        cta,
        banco,
        titular,
        sucursal,
        ejecutivo,
        direccion,
        telefono,
    } = values;

    const acGrabar = async( e ) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            ctaErr: cta.length === 0 ? true : false,
        });

        if (cta.length === 0) {
            dispatch(setError('ERROR: Se debe de registrar una cuenta'));
            return;
        }

        const manda = {
            ...values,
            alta: format(wDate, 'yyyyMMdd'),
        }

        let resp = alta ? await saveBanco( manda ) : await updateBanco( manda );
        if ( resp.coderesult !== 200 ) {
            dispatch( setError( resp.Message ));
        } else {
            showSuccess('Proceso OK');
            edita( manda )
            // reset();
            onClose();
        }
    }


    return (
        <>
            <Dialog
                open={open}
                PaperComponent={PaperComponent}
                aria-labelledby="CapBan"
                maxWidth='md' fullWidth={true}
            >
                <DialogTitle sx={{ px: 3, py: 2, cursor: 'move', color: 'primary.main', }} >
                    {titulo}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        { msgError && ShowError( 'error', msgError ) }
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextEdit required autoFocus name='cta' value={cta} label='Cuenta' onChange={onInputChange}
                                    error={fieldsErr.ctaErr} 
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextDate required label='Apertura' value={wDate} onChange={(date) => { setwDate(date) }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='titular' value={titular} label='Nombre' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextEdit name='banco' value={banco} label='Banco' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextEdit name='sucursal' value={sucursal} label='Sucursal' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='ejecutivo' value={ejecutivo} label='Ejecutivo' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='direccion' value={direccion} label='Direccion' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextEdit name='telefono' value={telefono} label='Telefono' onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={4}>

                                    <Autocomplete
                                        id="select-moneda"
                                        sx={{ width: 200 }}
                                        options={monedas}
                                        autoHighlight
                                        size='small'
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > span': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <span>{countryToFlag(option.code)}</span>
                                                {option.label} ({option.code})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextEdit
                                                {...params}
                                                label="Moneda"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>


                            </Grid>

                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={acGrabar} >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={onClose} >
                        Cancelar
                    </BotonCancel>
                </DialogActions>

            </Dialog>

        </>
    )
}
