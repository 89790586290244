import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import { useForm } from '../hooks/useForm';
import { TextEdit } from '../FileShared/TextEdit';
import { TextDate } from '../FileShared/TextDate';
import { LookupBanco } from '../components/LookupBanco'
import { BotonCancel, BotonOK } from '../Styles/Styles';
import { setError } from '../actions/acUI';
import { showSuccess } from '../FileShared/Util/Util';
import { saveMovBan } from '../actions/acBancos';
import { ShowError } from '../FileShared/ShowError/ShowError';
import { useNavigate } from 'react-router-dom';


const PaperComponent = (props) => {
    return (
        <Draggable handle="#CapBan" cancel={'[class*="MuiDialogContent-root"]'} >
            <Paper {...props} />
        </Draggable>
    );
}

export const MovBan = ({ registro }) => {
    
    const inicial = {
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    }

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [wDate, setwDate] = useState(new Date());
    const [banco, setBanco] = useState(inicial);
    const [continuar, setContinuar] = useState(true);
    const navigate = useNavigate();
    
    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        ctaErr: false,
        fechaErr: false,
    });

    const [values, onInputChange, reset] = useForm({
        cheque: '',
        concepto: '',
        cargo: 0,
        abono: 0,
        concilia: ' ',
        status: 'MO',
        tipopago: null,
    });

    const {
        cheque,
        concepto,
        cargo,
        abono,
    } = values;

    const { msgError } = useSelector(state => state.ui);

    useEffect(() => {
        // console.log( values );
    }, [msgError])

    const showError = (msg, tipo) => {
        ShowError('error', msg);
        dispatch(setError(null));
    }

    const onClose = () => {
        setOpen(false);
        navigate('/', {
            replace: true
        });
    }

    const acGrabar = async (e) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            ctaErr: banco.cta?.length === 0 ? true : false,
            importe0Err: (cargo === 0 && abono === 0) ? true : false,
            importe2Err: (cargo !== 0 && abono !== 0) ? true : false,
        });

        if (banco.cta === undefined || banco.cta?.length === 0) {
            dispatch(setError('ERROR: Numero de cuenta no valido'));
            return;
        }

        if (cargo === 0 && abono === 0) {
            dispatch(setError('ERROR: Se debe de registrar un importe'));
            return;
        }

        if (cargo !== 0 && abono !== 0) {
            dispatch(setError('ERROR: No se puede registrar movimiento con retiro y deposito simultáneamente'));
            return;
        }
        if (cargo < 0 || abono < 0) {
            dispatch(setError('ERROR: Los importes no pueder ser negativos'));
            return;
        }

        const manda = {
            ...values,
            idbanco: banco.idbanco,
            cta: banco.cta,
            fecha: format(wDate, 'yyyyMMdd'),
        }

        const resp = await saveMovBan(manda);
        if (resp.coderesult !== 200) {
            dispatch(setError(resp.Message));
        } else {
            showSuccess('Registrado OK');
            reset();

            !continuar && onClose();
        }
    }


    return (
        <>
            <Dialog 
                open={open} 
                PaperComponent={PaperComponent} 
                aria-labelledby="MovBan"
                maxWidth='md' 
                fullWidth={true} 
            >
                <DialogTitle 
                    sx={{
                        px: 3,
                        py: 2,
                        cursor: 'move',
                        color: 'primary.main'
                    }}
                    id="MovBan"
                > 
                    Movimientos Bancarios 
                </DialogTitle>

                <DialogContent dividers={true}>
                    <DialogContentText >
                        { msgError && showError( msgError, 'error') }
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <LookupBanco required autoFocus name='cta' label='Cuenta' cta={banco.cta} setValues={setBanco} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextDate required label='Fecha' value={wDate} onChange={date => setwDate(date)} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit name='cheque' value={cheque} label='Referencia' fullWidth onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='concepto' value={concepto} label='Concepto' fullWidth onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='cargo' value={cargo} label='Retiro' fullWidth onChange={onInputChange} error={fieldsErr.importe0Err || fieldsErr.importe2Err}
                                        inputProps={{ min: 0, style: { textAlign: 'right' } }} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='abono' value={abono} label='Deposito' fullWidth onChange={onInputChange} error={fieldsErr.importe0Err || fieldsErr.importe2Err}
                                        inputProps={{ min: 0, style: { textAlign: 'right' } }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox checked={continuar} onChange={(e) => setContinuar(e.target.checked)} />} label="Continuar con otra captura despues de grabar" />
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={acGrabar} >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={onClose} >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>

        </>
    )
}
