import React from 'react';
import TextField from '@mui/material/TextField';

export const TextEdit = ( props ) => {
    return (
        <TextField 
            variant='outlined' 
            margin='none' 
            size='small' 
            fullWidth 
            {...props} 
        />
    )
}
