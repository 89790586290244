import { types } from '../types/types';

const inicial = {
    isLogin: false,
    uid: '',
    name: '',
    mail: '',
    img: '',
}

export const authReducer = (state = inicial, action) => {
    switch (action.type) {
        case types.login:
            return {
                isLogin: true,
                id: action.payload.id,
                uid: action.payload.uid,               
                name: action.payload.name
            }
        case types.logout:
            return inicial

        default:
            return state
    }
}