import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';


import { useForm } from '../hooks/useForm';
import { TextEdit } from '../FileShared/TextEdit';
import { TextDate } from '../FileShared/TextDate';
import { LookupBanco } from '../components/LookupBanco'
import { BotonCancel, BotonOK } from '../Styles/Styles';
import { setError } from '../actions/acUI';
import { showSuccess } from '../FileShared/Util/Util';
import { ShowError } from '../FileShared/ShowError/ShowError';
import { useNavigate } from 'react-router-dom';
import { saveTraspaso } from '../actions/acBancos';



const PaperComponent = (props) => {
    return (
        <Draggable handle="#Cap-Traspasos-Bancarios" cancel={'[class*="MuiDialogContent-root"]'} >
            <Paper {...props} />
        </Draggable>
    );
}


export const Traspasos = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [wDate, setwDate] = useState(new Date());
    const navigate = useNavigate();
    const [origen, setOrigen] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    });
    const [destino, setDestino] = useState({
        idbanco: -1,
        cta: '',
        titular: '',
        banco: ''
    });

    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        origenErr: false,
        destinoErr: false,
        fechaErr: false,
        Importe: false,
    });

    const [values, onInputChange, reset] = useForm({
        refer: '',
        concepto: '',
        importe: 0,
        status: 'TR',
    });

    const {
        importe,
    } = values;

    const { msgError } = useSelector(state => state.ui);

    const onClose = () => {
        setOpen(false)
        navigate('/', { replace: true });
    }

    const acGrabar = async (e) => {
        e.preventDefault();
        setFieldsErr({
            ...fieldsErr,
            origenErr: origen.cta?.length === 0 ? true : false,
            destinoErr: destino.cta?.length === 0 ? true : false,
            importe: (importe <= 0) ? true : false,
        });

        if (origen.cta?.length === 0) {
            dispatch(setError('ERROR: Se debe de registrar una cuenta origen'));
            return;
        }
        if (destino.cta?.length === 0) {
            dispatch(setError('ERROR: Se debe de registrar una cuenta destino'));
            return;
        }
        if (origen.cta === destino.cta) {
            dispatch(setError('ERROR: No puden ser las mismas cuentas'));
            return;
        }

        if (importe <= 0) {
            dispatch(setError('ERROR: Importe no valido'));
            return;
        }
        if (!wDate) {
            return
        }

        const manda = {
            ...values,
            idorigen: origen.idbanco,
            iddestino: destino.idbanco,
            origen: origen.cta,
            destino: destino.cta,
            fecha: format(wDate, 'yyyyMMdd'),
        }

        const resp = await saveTraspaso(manda);
        if (resp.coderesult !== 200) {
            dispatch(setError(resp.Message));
        } else {
            reset();
            onClose();
        }
    }

    useEffect(() => {
        // console.log( values );
    }, [msgError])

    const showError = (msg, tipo) => {
        ShowError('error', msg);
        dispatch(setError(null));
    }


    return (
        <>
            <Dialog open={open} PaperComponent={PaperComponent} maxWidth='md' fullWidth={true} 
            aria-labelledby="Cap-Traspasos-Bancarios"
            >
                <DialogTitle
                    sx={{
                        px: 3,
                        py: 2,
                        cursor: 'move',
                        color: 'primary.main'
                    }}
                    id="Cap-Traspasos-Bancarios"
                >
                    Traspasos Bancarios
                </DialogTitle>

                <DialogContent dividers={true}>
                    <DialogContentText >
                        {msgError && showError(msgError, 'error')}
                        <form autoComplete='off'>
                        <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <LookupBanco required autoFocus name='cta' label='Cuenta origen' cta={ origen.cta } setValues={setOrigen} />
                                </Grid>    
                                <Grid item xs={12} md={6}>
                                    <LookupBanco required name='cta' label='Cuenta destino' cta={ destino.cta } setValues={setDestino} />
                                </Grid>    
                                <Grid item xs={6} md={3}>
                                    <TextDate required label='Fecha' value={wDate} onChange={ date => setwDate( date ) } />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='importe' value={importe} label='Importe' onChange={ onInputChange } error={fieldsErr.importe } 
                                    inputProps={{min: 0, style: { textAlign: 'right' }}} />
                                </Grid>    
                            </Grid>
                        </form>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={acGrabar} >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={onClose} >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>

        </>
    )
}
