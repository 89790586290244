export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    userName: '[User] Name',
    userSetUsuario: '[User] SetUsuario',
    userLogout: '[User] Logout user',

    uiOpenMenu: '[UI] Open Main menu',
    uiCloseMenu: '[UI] Close Main menu',
    uiOpenMenuUser: '[UI] Open User menu',
    uiCloseMenuUser: '[UI] Close User menu',
    uiDarkMode: '[UI] Set Dark mode',
    uiLightMode: '[UI] Set Light mode',
    uiMenuID: '[UI] Set ID Menu',
    uiMenuUserAnchorEl: '[UI] Set AhchorEl',
    uiMarcaError: '[UI] Muestra error',
    uiSetLoading: '[UI] Set loading',
    uiSetWorking: '[UI] Set Working',
    uiSetMenuSkin: '[UI] Set Menu Skin',
    uiSetTema: '[UI] Set Tema',
    uiSetSkin: '[UI] Set Skin',

    sucLoad: '[SUC] Load sucursales',
    
    almacenSetData: '[Almacen] Load data',
    almacenClose: '[Almacen] Clear data',
    almacenSetRegistro: '[Almacen] Set record almacen'


}