import React from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';

export const Splash = () => {
  return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: '#000',
                    color: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >

                <Typography
                    className='animate__animated animate__fadeInRightBig'
                    variant="h2"
                    component="h2"
                >
                    Loading...
                </Typography>
            </Box>
        </>
    )
}
