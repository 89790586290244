import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import InputIcon from '@mui/icons-material/Input';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ListIcon from '@mui/icons-material/List';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const MenuOpciones = [
    // { id:  0, nvl: 0, parentid:  0, label: 'Inicio', icon: <HomeIcon />, link:'/' },
    { id:  1, nvl: 0, parentid:  0, label: 'Bancos', icon: <AccountBalanceIcon />, link:'' },
    { id:  5, nvl: 0, parentid:  0, label: 'Configuracion', icon: <SettingsIcon />, link:'' },

    { id: 10, nvl: 1, parentid:  1, label: 'Cuentas bancarias', icon: <AccountBalanceIcon />, link:'/cuentas' },
    { id: 11, nvl: 1, parentid:  1, label: 'Movimientos', icon: <InputIcon />, link:'/movimientos' },
    { id: 12, nvl: 1, parentid:  1, label: 'Estado de cuenta', icon: <PlaylistAddCheckIcon />, link:'/edoctaban' },
    { id: 13, nvl: 1, parentid:  1, label: 'Reporte de saldos', icon: <ListIcon />, link:'/saldos' },
    { id: 14, nvl: 1, parentid:  1, label: 'Traspasos', icon: <SyncAltIcon />, link:'/traspasos' },
    { id: 15, nvl: 1, parentid:  1, label: 'Reporte de movimientos', icon: <AssignmentIcon />, link:'/repmov' },

    { id: 20, nvl: 1, parentid:  2, label: 'Alta', icon: <AddCircleIcon />, link:'/' },
    { id: 21, nvl: 1, parentid:  2, label: 'Reporte', icon: <MenuBookIcon />, link:'/' },
    { id: 22, nvl: 1, parentid:  2, label: 'Cancelacion', icon: <DeleteIcon />, link:'/' },

    { id: 50, nvl: 1, parentid:  5, label: 'Empresas', icon: <AddCircleIcon />, link:'/' },
    { id: 51, nvl: 1, parentid:  5, label: 'Usuarios', icon: <AddCircleIcon />, link:'/' },

]

export const menuUser = [
    { id: 'u1001', label: 'Notificaciones', icon: <NotificationsIcon />, link: ''},
    { id: 'u1002', label: 'Perfil', icon: <AccountCircleIcon />, link: ''},
]