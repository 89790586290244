import { types } from '../types/types';
import { setError, setLoading, setWorking } from './acUI';
import { setUser } from './acUsers';

// return JSON.parse( localStorage.getItem('user') ) || { logged: false};
// localStorage.setItem('user', JSON.stringify(user));

export const login = ( user, pass ) => {
    return ( dispatch ) => {        
        const url = `${ window.$baseApi }/TAuth/ValidaLogin/${ user }/${ pass }`;    
        try {
            dispatch( setLoading(true));
            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        localStorage.setItem('lastuser', JSON.stringify(data[0]) );                        
                        dispatch( setLogin( data[0] ));
                        dispatch( setUser( data[0] ));
                        dispatch( setLoading(false));
                    } else {
                        dispatch( setLoading(false));
                        dispatch( setError('Usuario no valido') );
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }    
}


export const getUser = ( user ) => {
    return ( dispatch ) => {
        const url = `${ window.$baseApi }/TAuth/GetUser/${ user.iduser }:${ user.token }`;
        try {
            dispatch( setLoading(true));
            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        dispatch( setLogin( data[0] ));
                        dispatch( setUser( data[0] ));
                        dispatch( setWorking( false ));
                        dispatch( setLoading( false ));
                    } else {
                        console.log('No se encontro')
                        dispatch( setLoading(false));
                        dispatch( setError('Usuario no valido') );
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }
}

const setLogin = ( usuario ) => ({
    type: types.login,
    payload: {
        isLogin: true,
        id: usuario.iduser,
        uid: usuario.token,
        name: usuario.nombre,
    }
})

export const logout = () => {
    return ( dispatch, getState ) => {
        // const user = localStorage.getItem('lastuser');
        const { id, uid } = getState().auth;
        const url = `${ window.$baseApi }/TAuth/signOut/${ id }:${ uid }`;
        localStorage.setItem('lastuser','');
        try {
            dispatch( setLoading(true));
            dispatch( setUser( {} ));
            dispatch( setLogout() );
            dispatch( setLoading(false));

            fetch( url ).then( res =>{
                res.json().then ( data => {
                    if ( data !== null ) {
                        // dispatch( setUser( {} ));
                        dispatch( setLoading(false));
                        dispatch( setLogout() );
                    } else {
                        dispatch( setLoading(false));
                    }
                })
            })
            .catch(err => {
                dispatch( setLoading(false));
                dispatch( setError( err ) );
            })
            
        } catch ( err ) {
            dispatch( setLoading(false));
            dispatch( setError( err ) );
        }     
    }
}

const setLogout = () => ({
    type: types.logout
})