import * as React from 'react';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';


export const TextDate = ( props ) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={ esLocale }>
      <DatePicker
        { ...props }
        showTodayButton
        // showToolbar
        renderInput={(params) => 
        <TextField 
          size='small' 
          fullWidth 
          margin='none' 
          variant='outlined'
          {...params} 
        />}
      />
    </LocalizationProvider>
  );
}