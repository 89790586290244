import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, 
  FormControl, 
  FormHelperText, 
  IconButton, 
  InputAdornment, 
  InputLabel, 
  OutlinedInput 
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import imgLogo from '../assets/img/Logo.png';
import imgLogoCIA from '../assets/img/LogoCIA.png';
import imgFondo from '../assets/img/login.jpg';
import { useForm } from '../hooks/useForm';
import { login } from '../actions/acAuth';
import { setError } from '../actions/acUI';
import { ShowError } from '../FileShared/ShowError/ShowError';

const theme = createTheme();
const useStyles = makeStyles({
  logo: {
    maxWidth: '220px',
    marginBottom: theme.spacing(3),
    // borderRadius: '90px',
    // border: '1px solid #ddd',
    // boxShadow: '5px 5px 10px 1px rgb(0, 0, 0, 0.3)'
  },
  mylogo: {
    width: '64px',
    marginTop: theme.spacing(1),
  }
});

const Copyright2 = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

        }}
      >
        <Typography variant='caption' color='text.secondary' >
          Powered by
        </Typography>

        <img

          src={imgLogo}
          alt='systemaz'
          style={{ width: '48px'}}
          />
          </Box>

    </>
  )
}

const Copyright = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent='center'
      sx={{ mt: 7, }}
    >
      <Grid
        container
        item xs={4}
        alignItems="center"
        justifyContent='center'
        sx={{ p: 2}}
      >
        <Typography variant='caption' color='text.secondary' >
          Powered by
        </Typography>
        <img
          className={classes.mylogo}
          src={imgLogo}
          alt='systemaz'
        />
      </Grid>

      <Grid item xs={8}  >
        <Box>

        <Typography variant="caption" color="text.secondary" align="center" {...props} gutterBottom={true} >
          {'Copyright © '}
          <Link color="inherit" href="#" sx={{ textDecoration: 'none' }}>
            Systemaz
          </Link>
          {' '}{new Date().getFullYear()}{'.'}
        </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { msgError, loading } = useSelector(state => state.ui);

  const openError = Boolean(msgError);

  const showError = (msg, tipo) => {
    ShowError(tipo, msg);
    dispatch(setError( null ));
  }

  const [values, onInputChange] = useForm({
    usuario: '',
    clave: '',
  });

  const { usuario, clave } = values;
  const [showPassword, setShowPassword] = React.useState(false);
  const [fieldsErr, setFieldsErr] = React.useState({
    usuarioErr: false,
    claveErr: false
  })

  const handleClickShowPassword = () => {
    setShowPassword( !showPassword );
};

  const handleValidaLogin = (e) => {
    
    e.preventDefault();
    setFieldsErr({
      ...fieldsErr,
      usuarioErr: usuario.length === 0 ? true : false,
      claveErr: clave.length === 0 ? true : false,
    })
    
    if (usuario.length > 0 && clave.length > 0 ) {
      dispatch( login( usuario, clave ) );
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${imgFondo})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: ( theme ) =>
          theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img className={classes.logo} src={imgLogoCIA} alt="Logo" />

            <Typography component="h1" variant="h5">
              Inicio de sesión
            </Typography>
            <Box component="form" noValidate onSubmit={handleValidaLogin} sx={{ mt: 2, maxWidth: 480 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                value={ usuario }
                autoComplete="off"
                autoFocus
                error={ fieldsErr.usuarioErr}
                onChange={ onInputChange }
              />
              <FormHelperText error className={classes.errortext}>
                { fieldsErr.usuarioErr ? 'Se requiere usuario' : <span>&nbsp;</span> }
              </FormHelperText>

              <FormControl 
                variant="outlined"
                fullWidth
                required
                margin="normal"
                error={fieldsErr.claveErr}
                >
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <OutlinedInput
                    id="password"
                    type={ showPassword ? 'text' : 'password'}
                    name='clave'
                    value={ clave }
                    autoComplete="current-password"
                    onChange={ onInputChange }                    
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={ handleClickShowPassword }
                        // onMouseDown={ handleMouseDownPassword }
                        edge="end"
                        >
                        { showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label='Contraseña'
                />
                </FormControl>
                <FormHelperText error className={classes.errortext}>
                  { fieldsErr.claveErr ? 'Se requiere contraseña' : <span>&nbsp;</span> }
                </FormHelperText>


              <Button
                type="submit"
                fullWidth
                variant="contained"
                color='primary'
                disabled={ loading }
                sx={{ mt: 3, mb: 2, textTransform: 'none' }}
              >
                Iniciar sesión
                { 
                  loading && <CircularProgress size={48} 
                    className={ classes.buttonProgress } 
                    sx={{ position: 'absolute', color: 'red'}}
                  />
                }
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {'¿No tienes una cuenta?'}
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        { openError && showError( 'ERROR: ' + msgError, 'error') }
      </Grid>
    </ThemeProvider>
  );
}