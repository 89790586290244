import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSelector } from 'react-redux';
import { Box, Fab, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Waiting } from '../Wating/Waiting';
// import { styles } from './StylesCatalogos';
// import { MyMenu } from "../../components/MyMenu";
import { MenuBrow } from "../../components/MenuBrow";


export const CustomCatalogos = ({
  titulo,
  campos,
  data,
  onClickAdd,
  onClickMenu,
  acOpciones = [],
  menuOpciones = [],
}) => {
  // const classes = styles();
  const { loading } = useSelector(state => state.ui);

  const [anchorEl, setAnchorEl] = useState(null);
  const [record, setRecord] = useState({});

  const handleClickMenu = (e, registro) => {
    setAnchorEl(e.currentTarget);
    setRecord(registro);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (accion) => {
    setAnchorEl(null);
    onClickMenu( accion, record );
  }

  const txtEtiquetas = {
    body: {
      noMatch: "No se encontraron registros...",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Sig Pagina",
      previous: "Pagina anterior",
      rowsPerPage: "Registros por pagina:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtros",
    },
    filter: {
      all: "Todo",
      title: "FILTROS",
      reset: "LIMPIAR",
    },
    viewColumns: {
      title: "Mostrar Columnas",
      titleAria: "Show/Hide Table Columns",
    },
    selectedRows: {
      text: "row(s) selected",
      delete: "Borrar",
      deleteAria: "Delete Selected Rows",
    },
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    // pagination: false,
    tableBodyHeight: 'calc(100vh - 180px)',
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 50, 100, 500],
    searchPlaceholder: 'Buscar...',
    selectableRows: 'none',
    draggableColumns: { enabled: true, },
    textLabels: txtEtiquetas,
    // onRowClick: onClickRow,

    // resizableColumns: true,
    // onRowClick: (row, index) => { console.log(row, index ); },
    // customToolbar: () => {
    //   return (
    //     <CustomToolbar />
    //   );
    // },

    setTableProps: () => {
      return {
        // padding: 'none',

        // material ui v4 only
        // size: 'medium',
      };

    },
  };

  const opcionesMenu = [
    ...acOpciones,
    // { id:  1000, caption: 'Editar', icon: <EditIcon />, accion: 'acEdit' },
    // { id:  2000, caption: 'Borrar', icon: <DeleteIcon />, accion: 'acDelete' },
  ];

  const MenuCatalogos = () => {
    return (
      <>
        <MenuBrow
          id='MenuOpciones'
          key={record[1]}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {
            opcionesMenu.map(opcion => (
              <MenuItem key={opcion.id} onClick={() => handleClick(opcion.accion)}>
                <ListItemIcon> {opcion.icon} </ListItemIcon>
                <ListItemText>{opcion.caption}</ListItemText>
              </MenuItem>
            ))
          }
        </MenuBrow>
      </>
    )
  }

  const menuCol =
  {
    name: "actions",
    label: " ",
    options: {
      viewColumns: false,
      filter: false,
      sort: false,
      empty: true,
      print: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <IconButton onClick={(e) => { handleClickMenu(e, tableMeta.rowData); }} >
              <MoreVertIcon />
            </IconButton>
            {tableMeta.rowData[1] === record[1] && <MenuCatalogos opcionesMenu={menuOpciones} record={record} onClickMenu={onClickMenu} />}
          </>
        );
      }
    }
  }

  const columnas = [menuCol, ...campos];

  return (
    <Box 
      // className={classes.tabla}
      sx={{ flex: 1, height: '100%' }}
    >
      <div>
        {loading && <Waiting />}
        <MUIDataTable
          // className={classes.root}
          title={titulo}
          columns={columnas}
          options={options}
          data={data}
        />
      </div>
      <Box 
        // className={classes.boxAdd}  
        sx={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '40px', marginTop: '-80px',}}
      >
        <Zoom in={true} style={{ transitionDelay: '100ms' }} >
          <Fab
            size="large"
            aria-label="Nuevo registro"
            color='primary'
            onClick={onClickAdd}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      </Box>


    </Box>
  )
}
