import Swal from 'sweetalert2';
import './util.css';

export const siBorrar = () => {
    return Swal.fire({
        icon: 'warning',
        title: 'Esta seguro?',
        text: 'Este proceso es irreversible',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo',
        cancelButtonText: 'Cancelar',
        focusCancel: true,
        preConfirm: ( isConfirm ) => {
            return isConfirm;
        }
    }).then( response => {
        Swal.close();
        return response.value;
    })
}

export const showSuccess = ( msg ) => {
    Swal.fire({
        icon: 'success',
        // position: 'top-end',
        title: msg,
        showConfirmButton: false,
        timer: 1200,
        toast: true,
        customClass: {
            container: 'swal-all-top'
        }
    })
}

export const showMessage = ( msg ) => {
    Swal.fire({
        icon: 'warning',
        // position: 'top-end',
        title: msg,
        showConfirmButton: false,
        timer: 1200,
        toast: true,
        customClass: {
            container: 'swal-all-top'
        }
    })
}

export const setPesos = ( value ) => {
    const nf = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return nf.format(value);
}

export const setCantidad = ( value, decimales = 0) => {
    const nf = new Intl.NumberFormat("es-MX", {
        // style: "currency",
        // currency: "USD",
        minimumFractionDigits: decimales,
        maximumFractionDigits: decimales
      });
      return nf.format(value);
}

export const countryToFlag = (isoCode)  => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
}

export const monedas = [
    { code: 'MX', label: 'Peso MX', phone: '52', suggested: true  },
    { code: 'US', label: 'Dolar US', phone: '1' },
]