import React from 'react';
import { Badge, Box, Drawer, Grid, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import { setMenuSkin, setSkin } from '../actions/acUI';
import { opcionesTema, themesConfig } from '../Styles/Estilos';

export const SelectThem = () => {
  const dispatch = useDispatch();
  const { menuSkin } = useSelector(state => state.ui);

  const onClose = () => {
    dispatch(setMenuSkin(false));
  }

  const Tema = ({ nombre }) => {
    let objeto;
    for (let i in themesConfig) {
      if (themesConfig[i].name === nombre) {
        objeto = themesConfig[i];
      }
    }

    const onClickSkin = (e) => {
      localStorage.setItem('skin', e );                        
      dispatch( setSkin( e ));
      onClose();
    }

    const aTema = createTheme({ ...objeto });
    return (
      <ThemeProvider theme={aTema}>
        <Grid item lg={6} md={12} xs={12} >
          <Paper 
            elevation={3} 
            sx={{ height: 160, m: 2, bgcolor: 'background.default', cursor: 'pointer', '&:hover': { boxShadow: '5px 10px 8px #888', }, }}
            onClick={() => onClickSkin( nombre ) }
          >
            <Paper elevation={0} sx={{ bgcolor: 'primary.main', height: 50, }}>  </Paper>
            <Paper elevation={1} sx={{ bgcolor: 'background.paper', width: '75%', height: 100, mt: -3, ml: 1, }}>  </Paper>
            <Badge badgeContent={8} color='secondary'  
              sx={{ 
                right: 3,
                // top: -13,
                padding: '0 4px'
              }}
            >
            <Typography color='text.primary' sx={{ m: 1 }} variant='body2'>{nombre}</Typography>
            </Badge>
          </Paper>
        </Grid>
      </ThemeProvider>
    )
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={menuSkin}
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: '25vw',
          }
        }}
      >

        <Typography variant='body' sx={{ p: 1, mr: 3, }}>Selecciona la combinacion de colores con que quieres que tu aplicacion se viusualice</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'end', }}>
          <IconButton color='inherit' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ height: '90vh', overflow: 'scroll' }}>
          <Grid container spacing={2}>
            {opcionesTema.map(item => (<Tema key={item.id} nombre={item.label} />))}
          </Grid>
        </Box>
      </Drawer>
    </>


  )
}
