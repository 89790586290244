import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PublicRoute = ({ children }) => {
    const { isLogin } = useSelector(state => state.auth);

    return isLogin
        ? <Navigate to='/' />
        : children
}
