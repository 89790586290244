import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { format, toDate } from 'date-fns';
// import Swal from 'sweetalert2';

import { useForm } from '../hooks/useForm';
import { TextEdit } from '../FileShared/TextEdit';
import { TextDate } from '../FileShared/TextDate';
import { BotonCancel, BotonOK } from '../Styles/Styles';
import { setError } from '../actions/acUI';
import { showSuccess } from '../FileShared/Util/Util';
import { updateMovBan } from '../actions/acBancos';
import { ShowError } from '../FileShared/ShowError/ShowError';

const PaperComponent = (props) => {
    return (
        <Draggable handle="#EditMov" cancel={'[class*="MuiDialogContent-root"]'} >
            <Paper {...props} />
        </Draggable>
    );
}


export const EditMov = ({ open, onClose, registro, edita }) => {

    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);
    const [wDate, setwDate] = useState( toDate(new Date( registro.fecha + ' 12:12')) );

    const [fieldsErr, setFieldsErr] = React.useState({
        ValidaDatos: false,
        ctaErr: false,
        fechaErr: false,
    });

    const showError = (msg, tipo) => {
        ShowError('error', msg );
        dispatch(setError(null));
    }

    const [values, onInputChange] = useForm(registro);
    const { titular, cheque, concepto, cargo, abono, status } = values;

    // if ( status !== 'MO') {
    //     Swal.fire({
    //         // position: 'top-end',
    //         icon: 'error',
    //         // title: 'Error',
    //         text: 'Error: Ese registro no se puede modificar',
    //         // toast: true,
    //         customClass: {
    //             container: 'my-swal'
    //         }
    //       })
    //       onClose();
    // }

    const acGrabar = async (e) => {
        e.preventDefault();
   
        setFieldsErr({
            ...fieldsErr,
            importe0Err: (cargo === 0 && abono === 0) ? true : false,
            importe2Err: (cargo !== 0 && abono !== 0) ? true : false,
        });

        if (cargo === 0 && abono === 0) {
            dispatch(setError('ERROR: Se debe de registrar un importe'));
            return;
        }

        if (cargo * abono !== 0) {
            dispatch(setError('ERROR: No se puede registrar movimiento con retiro y deposito simultáneamente'));
            return;
        }

        if (cargo < 0 || abono < 0) {
            dispatch(setError('ERROR: Los importes no pueder ser negativos'));
            return;
        }

        const manda = {
            ...values,
            fecha: format(wDate, 'yyyyMMdd'),
        }

        const resp = await updateMovBan(manda);
        if (resp.coderesult !== 200) {
            dispatch(setError(resp.Message));
        } else {
            showSuccess('Proceso OK');
            edita( manda );
            onClose();
        }
    }

    return (
        <>
            <Dialog
                open={open}
                PaperComponent={PaperComponent}
                aria-labelledby="EditMov"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle
                    sx={{
                        px: 3,
                        py: 2,
                        cursor: 'move',
                        color: 'primary.main'
                    }}
                    id="MovBan"
                >
                    Movimientos Bancarios
                </DialogTitle>

                <DialogContent dividers={true}>
                    <DialogContentText >
                        {msgError && showError(msgError, 'error')}
                        <form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <TextEdit name='titular' label='Cuenta' value={titular} disabled />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextDate required label='Fecha' value={wDate} onChange={date => setwDate(date)} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit name='cheque' value={cheque} label='Referencia' fullWidth onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextEdit name='concepto' value={concepto} label='Concepto' fullWidth onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='cargo' value={cargo} label='Retiro' fullWidth onChange={onInputChange} error={fieldsErr.importe0Err || fieldsErr.importe2Err}
                                        inputProps={{ min: 0, style: { textAlign: 'right' } }} />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextEdit type='number' name='abono' value={abono} label='Deposito' fullWidth onChange={onInputChange} error={fieldsErr.importe0Err || fieldsErr.importe2Err}
                                        inputProps={{ min: 0, style: { textAlign: 'right' } }} />
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <BotonOK type="submit" variant='outlined' onClick={acGrabar} >
                        Grabar
                    </BotonOK>
                    <BotonCancel variant='outlined' onClick={onClose} >
                        Cancelar
                    </BotonCancel>
                </DialogActions>


            </Dialog>

        </>
    )
}
