import { types } from '../types/types';

export const mainMenuOpen = () => ({
    type: types.uiOpenMenu
});

export const mainMenuClose = () => ({
    type: types.uiCloseMenu
});

export const setLoading = (status) => ({
    type: types.uiSetLoading,
    payload: status
})

export const setError = (err) => ({
    type: types.uiMarcaError,
    payload: err
})

export const setWorking = (status) => ({
    type: types.uiSetWorking,
    payload: status
})

export const setMenuSkin = (aValue) => ({
    type: types.uiSetMenuSkin,
    payload: aValue
})
export const setSkin = (aValue) => {
    return (
        {
            type: types.uiSetSkin,
            payload: aValue
        }
    )
}


export const setTema = (tema) => {

    console.log(tema)
    return (
        {
            type: types.uiSetTema,
            payload: tema
        }
    )
}