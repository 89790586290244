import React, { useState } from 'react';
import { Box, Fab, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Acordion, AcordionSummary, AcordionDetalle } from '../Acordion';
import { Waiting } from '../Wating/Waiting';
import { TextDate } from '../TextDate';
import { MenuBrow } from '../../components/MenuBrow';

export const CustomReporte = ({
    titulo,
    campos,
    data,
    acExecute,
    onClickMenu,
    extra,
    menuOpciones = [],
    opciones = [] }) => {

    const { loading } = useSelector(state => state.ui);
    const [verParams, setVerParams] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [record, setRecord] = useState({});

    const [fechas, setFechas] = useState({
        wDate1: new Date(),
        wDate2: new Date()
    });

    const handleClickMenu = (e, registro) => {
        setAnchorEl(e.currentTarget);
        setRecord(registro);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (accion) => {
        setAnchorEl(null);
        onClickMenu(accion, record);
    }

    const { wDate1, wDate2 } = fechas;

    const txtEtiquetas = {
        body: {
            noMatch: "UPS, No se encontraron registros...",
            toolTip: "Ordenar",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
            next: "Sig Pagina",
            previous: "Pagina anterior",
            rowsPerPage: "Registros por pagina:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "Filtros",
        },
        filter: {
            all: "Todo",
            title: "FILTROS",
            reset: "LIMPIAR",
        },
        viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
            text: "row(s) selected",
            delete: "Borrar",
            deleteAria: "Delete Selected Rows",
        },
    }

    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
        fixedHeader: true,
        // pagination: false,
        tableBodyHeight: 'calc(100vh - 230px)',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100, 500],
        searchPlaceholder: 'Buscar...',
        selectableRows: 'none',
        draggableColumns: { enabled: true, },
        textLabels: txtEtiquetas,
        ...opciones
    };

    const opcionesMenu = [
        ...menuOpciones,
    ];

    const MenuReportes = () => {
        return (
            <>
                <MenuBrow
                    id='MenuOpciones'
                    key={record[1]}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {
                        opcionesMenu.map(opcion => (
                            <MenuItem key={opcion.id} onClick={() => handleClick(opcion.accion)}>
                                <ListItemIcon> {opcion.icon} </ListItemIcon>
                                <ListItemText>{opcion.caption}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </MenuBrow>
            </>
        )
    }


    const menuCol =
    {
        name: "actions",
        label: " ",
        options: {
            viewColumns: false,
            filter: false,
            sort: false,
            empty: true,
            print: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <>
                        <IconButton onClick={(e) => { handleClickMenu(e, tableMeta.rowData); }} >
                            <MoreVertIcon />
                        </IconButton>
                        {tableMeta.rowData[1] === record[1] && <MenuReportes opcionesMenu={menuOpciones} record={record} onClickMenu={onClickMenu} />}
                    </>
                );
            }
        }
    }


    const columnas = [menuCol, ...campos];


    const acRun = () => {
        acExecute(fechas)
        setVerParams(false)
    }

    return (
        <Box sx={{ height: '100%' }}>
            {loading && <Waiting />}
            <Acordion
                expanded={verParams}
                onChange={() => setVerParams(!verParams)}
            >
                <AcordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography >Parametros</Typography>
                </AcordionSummary>
                <AcordionDetalle>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <Fab
                            size='large'
                            onClick={acRun}
                            color='primary'
                            sx={{ position: 'fixed' }}
                        >
                            <SearchIcon />
                        </Fab>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={5} md={3} >
                            <TextDate
                                required
                                label='Fecha Inicial'
                                value={wDate1}
                                autoFocus
                                onChange={date => setFechas({
                                    ...fechas,
                                    wDate1: date
                                })}
                            />
                        </Grid>
                        <Grid item xs={5} md={3} >
                            <TextDate
                                required
                                label='Fecha Final'
                                value={wDate2}
                                autoFocus
                                onChange={date => setFechas({
                                    ...fechas,
                                    wDate2: date
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {extra}
                        </Grid>
                    </Grid>
                </AcordionDetalle>
            </Acordion>
            <div>
                <MUIDataTable
                    title={titulo}
                    columns={columnas}
                    options={options}
                    data={data}
                />
            </div>
        </Box>
    )
} 
