import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { green, pink } from '@mui/material/colors';

import { Button, ThemeProvider } from '@mui/material';
import { SelectTema } from './Estilos';

const drawerWidth = 280;
export const theme = SelectTema( 'default' );

// export const theme = createTheme({
//     palette: {
//         // mode: "dark",
//         mode: 'light',
//         primary: {
//             main: blue[600],
//         },
//         secondary: {
//             // main: '#11cb5f',
//             main: deepOrange[500],
//         },
//         titulo: {
//             background: blue[600],
//             color: '#FFF',
//             // background: 'linear-gradient(rgb(55, 122, 220), rgb(51,115,209) 50%, rgb(49, 100, 198)',
//         },
//         aceptar: {
//             // main: '#5c6ac4',
//             main: green[500],
//         },
//         cancelar: {
//             main: red[500],
//         },
//     },
// });

export const ThemeBotones = createTheme({
    palette: {
        primary: green,
        secondary: pink,
    }
});

export const BotonOK = ( props ) => {
    return (
        <ThemeProvider theme={ThemeBotones}>
            <Button variant="contained" sx={{ textTransform: 'none'}} color="primary" { ...props }>
                { props.children }
            </Button>
        </ThemeProvider>
    )
}

export const BotonCancel = ( props ) => {  
    return (
        <ThemeProvider theme={ThemeBotones} >
            <Button variant="contained" sx={{ textTransform: 'none'}} color="secondary" { ...props }>
            { props.children }
            </Button>
        </ThemeProvider>
    )
}

export const globalStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    barTitulo: {
      // background: blue[700],
      // color: '#FFF',

      // background: theme.palette.titulo.background,
      // color: theme.palette.titulo.color,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    panel: {
      display: 'flex',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1, 1, 2)
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    toolbar: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: theme.spacing(8),
    },
    panelCIA: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: drawerWidth,
        // color: '#fff',
        // backgroundColor: 'rgb(73, 162, 46)',
        backgroundColor: 'rgb(36, 82, 142)',
        color: theme.palette.primary.contrastText,
    },
    logoPanel: {
        maxWidth: '200px',
        borderRadius: '90px',
    },
    drawerPaper: {
		width: drawerWidth,
	},
    btnBack: {
      verticalAlign: 'bottom',
      color: theme.palette.primary.main,
      // fontWeight: 'bold',
      // fontWeight: '500',
      cursor: 'pointer',
	},
    iconMenu: {
      minWidth: '40px',
      color: 'deepOrange',
      // color: theme.palette.titulo.background,
	},
    link: {
    color: theme.palette.text.primary,
		textDecoration: 'none',
	},   
  BotonAgregar: {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[600],
      },   
  },

  ContenedorDir: {
    display: 'flex',
    flexDirection: 'column',
    height: '480px',
    borderRight: '1px solid',
  },

  PanelDir: {
    flex: '1 1 auto',
    marginTop: '5px',
    overflow: 'scroll',
  },

  acciones: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0 12px',
    '&:hover': {
      color: theme.palette.secondary.main,
      transform: 'scale(1.2)',
    }	
  },
  
}));

