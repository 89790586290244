import { types } from "../types/types";

const inicial = {
    openMenu: false,
    openMenuUser: false,
    loading: false,
    darkState: false,
    menuID: 0,
    tema: 'default',
    userAnchorEl: null,
    msgError: null,
    working: false,
    menuSkin: false,
    skin: 'default',
}

export const uiReducer = (state = inicial, action) => {
    switch (action.type) {
        case types.uiOpenMenu:
            return {
                ...state,
                openMenu: true
            }
        case types.uiCloseMenu: 
            return {
                ...state,
                openMenu: false
            }
        case types.uiSetLoading:
            return {
                ...state,
                loading: action.payload
            }
        case types.uiMarcaError:
            return {
                ...state,
                msgError: action.payload
            }
        case types.uiSetWorking:
            return {
                ...state,
                working: action.payload
            }
        case types.uiSetTema:
            return {
                ...state,
                tema: action.payload
            }
        case types.uiSetMenuSkin:
            return {
                ...state,
                menuSkin: action.payload
            }
        case types.uiSetSkin:
            return {
                ...state,
                skin: action.payload
            }
        default:
            return state;
    }
}