import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { HomePage } from '../pages/HomePage';
import { BrowBan } from '../pages/BrowBan';
import { globalStyles } from '../Styles/Styles'
import { MovBan } from '../pages/MovBan';
import { EdoCtaBan } from '../pages/EdoCtaBan';
import { Traspasos } from '../pages/Traspasos';
import { RepSaldos } from '../pages/RepSaldos';
import { SelectTema } from '../Styles/Estilos';



export const RutasApp = () => {
    const classes = globalStyles();
    const { skin }  = useSelector( state => state.ui )
    const theme = SelectTema( skin );

    return (
        <ThemeProvider theme={ theme }>
            <HomePage />
            <div className={ classes.content }>
                {/* <div className={ classes.toolbar }> */}
                    <Routes>
                        <Route path='/cuentas' element={ <BrowBan />} />
                        <Route path='/movimientos' element={ <MovBan />} />
                        <Route path='/edoctaban' element={ <EdoCtaBan />} />
                        <Route path='/traspasos' element={ <Traspasos />} />
                        <Route path='/saldos' element={ <RepSaldos />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="*" element={<Navigate to='/' />} />

                    </Routes>
                {/* </div> */}
            </div>
            
        </ThemeProvider>
    )
}
