import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import { store } from './store/store';
import { theme } from './Styles/Styles';
import { AppRouter } from './routes/AppRouter';

window.$baseApi = 'http://gregorysoft.viewdns.net:38500/apiBancos/1.0.0.0'; 
// window.$baseApi = 'http://192.168.1.30:38500/apiBancos/1.0.0.0';

export const BiosBank = () => {
    const tipoNavegador = () => {
        const agente = window.navigator.userAgent;
        const navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];

        for (var i in navegadores) {
            if (agente.indexOf(navegadores[i]) !== -1) {
                return navegadores[i];
            }
        }
    }

    if (tipoNavegador() === 'MSIE') {
        return (
            <h2>Tu navegador no es valido paro ejecutar esta aplicación</h2>
        )
    }

    return (
        <Provider store={store} >
            <ThemeProvider theme={theme}>
                <AppRouter />
            </ThemeProvider>
        </Provider>
    )
}
